(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/nanoid/assets/javascripts/nanoid.js') >= 0) return;  svs.modules.push('/components/lb-utils/nanoid/assets/javascripts/nanoid.js');

'use strict';

const nanoid = (t = 21) => crypto.getRandomValues(new Uint8Array(t)).reduce(((t, e) => t += (e &= 63) < 36 ? e.toString(36) : e < 62 ? (e - 26).toString(36).toUpperCase() : e > 62 ? '-' : '_'), '');

if (svs.isServer) {
  module.exports = { nanoid };
} else {
  setGlobal('svs.components.lbUtils.nanoid', nanoid);
}


 })(window);